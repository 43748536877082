import React from 'react';
import { Link } from 'react-router-dom';

import DefaultSectionComp from '../../common/default-section-components/DefaultSectionComp';

const BlogsListComp = ({currentPageNum, blogsJsonData, paginationNumbers}) => {

    let blogsList = blogsJsonData.map((b) => {
        return (
            <div className='col-12 col-md-6 col-xl-4' key={b.key}>
                <div className='bg-white shadow rounded-3 overflow-hidden position-relative'>
                    <div className='image vh-100' style={{backgroundImage :`url(${b.blog_image})`, backgroundSize:"cover", backgroundPosition:'center'}}></div>
                    <div className='content p-4'>
                        <div className='title fw-bold text-truncate mb-1'>{b.title}</div>
                        <div className='desc mb-2'>{b.desc.substring(0, 120)+'...'}</div>
                    </div>
                    <Link to={`/blog/`+b.key} className='view-more position-absolute w-100 h-100 d-flex align-items-center justify-content-center top-0 left-0'>
                        <div className='icon rounded-circle d-flex align-items-center justify-content-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M440.6 273.4c4.7-4.5 7.4-10.8 7.4-17.4s-2.7-12.8-7.4-17.4l-176-168c-9.6-9.2-24.8-8.8-33.9 .8s-8.8 24.8 .8 33.9L364.1 232 24 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l340.1 0L231.4 406.6c-9.6 9.2-9.9 24.3-.8 33.9s24.3 9.9 33.9 .8l176-168z"/></svg>
                        </div>
                    </Link>
                </div>
            </div>
        )
    });

  return (
    <>
        <DefaultSectionComp className="light bg-img blogs-list">
            <div className='container'>
                <div className='row gy-5'>{blogsList}</div>
            </div>
            <nav>
                <ul className="pagination justify-content-center py-5">
                    <li className="page-item"><Link className="page-link border-0 bg-transparent" to={`/blogs/`+((currentPageNum <= 1) ? currentPageNum : parseInt(currentPageNum)-1)} tabIndex="-1" aria-disabled="true">Previous</Link></li>
                    {paginationNumbers.map((n,i)=>{ return( <li className={`page-item px-1`+((currentPageNum === n) ? ' active' : '')} key={i}> <Link to={`/blogs/`+n} className='page-link rounded-circle'>{n}</Link></li> ); })}
                    <li className="page-item"><Link className="page-link border-0 bg-transparent" to={`/blogs/`+((currentPageNum >= paginationNumbers.length) ? currentPageNum : parseInt(currentPageNum)+1)}>Next</Link></li>
                </ul>
            </nav>
        </DefaultSectionComp>
    </>
  )
}
export default BlogsListComp
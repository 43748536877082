import React from 'react'
import ContactFormComp from '../common/forms/ContactFormComp'
import DefaultSectionComp from '../common/default-section-components/DefaultSectionComp'
import HeaderComp from '../common/header/HeaderComp';
import FooterComp from '../common/footer/FooterComp';
import PageBannerComp from '../common/page-banner/PageBannerComp';
import { IMAGE_URL } from '../../../config/config';

const ContactComp = () => {

  let bannerDetails = {
    "title": "Contact Us",
    "image": IMAGE_URL+"images/bg.jpg"
  };

  return (
    <>
    
    <HeaderComp/>
      <PageBannerComp  bType="banner" bDetails={bannerDetails}/>
      <DefaultSectionComp>
        <div className='container'>
          <div className='row d-block d-lg-block d-xl-flex gy-lg-5 gy-5'>
            <div className='col-12 col-lg-6 col-xl-4  mx-lg-auto d-lg-block'>
              <div className='contactForm'>
                <ContactFormComp/>
              </div>
            </div>
            <div className='col-12 col-lg-12 col-xl-8'>
                <div className='contact-details'>
                  <h5 className='mb-4'>Visaka Industries Limited</h5>
                  <div className='d-block d-lg-flex d-xl-flex gap-2 mb-5'>
                    <div className='col-12 col-lg-4 col-xl-4 rounded p-4 shadow'>
                        <h6 className='fw-bold mb-3'>Corporate Office</h6>
                        <p className='mb-0 lh-lg'>Visaka Towers, 1-8-303/69/3</p>
                        <p className='mb-0 lh-lg'>S.P. Road, Secunderabad</p>
                        <p className='mb-0 lh-lg'>Andhra Pradesh, India – 500003</p>
                        <p className='mb-0 lh-lg'>Tel: 8886786398</p>
                    </div>
                    <div className='col-12 col-lg-4 col-xl-4 rounded p-4 shadow'>
                        <h6 className='fw-bold mb-3'>Factory 1</h6>
                        <p className='mb-0 lh-lg'>Visaka Industries Limited,</p>
                        <p className='mb-0 lh-lg'>Survey No. 95 & 96, Gajalapuram</p>
                        <p className='mb-0 lh-lg'>Vill: Near Miryalaguda,</p>
                        <p className='mb-0 lh-lg'>Post: Pedadevullapally</p>
                        <p className='mb-0 lh-lg'>Mandal: Tripuraram,</p>
                        <p className='mb-0 lh-lg'>Dist: Nalgonda-508207, Telangana.</p>
                        <p className='mb-0 lh-lg'>Tel: 8886786398</p>
                    </div>
                    <div className='col-12 col-lg-4 col-xl-4 rounded p-4 shadow'>
                        <h6 className='fw-bold mb-3'>Factory 2</h6>
                        <p className='mb-0 lh-lg'>Visaka Industries Limited,</p>
                        <p className='mb-0 lh-lg'>Gate no. 248 & 261 to 269</p>
                        <p className='mb-0 lh-lg'>Vill: Delwadi </p>
                        <p className='mb-0 lh-lg'>Daund taluka, Pune</p>
                        <p className='mb-0 lh-lg'>Maharashtra, India</p>
                    </div>
                  </div>
                  <div className='col-12'>
                      <h5 className='mb-4'>Marketing Offices</h5>
                    </div>
                  <div className="d-block d-lg-flex gap-3">
                    
                    <div className="col-12 col-lg-4 col-xl-4">
                      <div className="list-group list-group-flush shadow px-4 py-4 rounded h-100" id="list-tab" role="tablist">
                        <a className="list-group-item list-group-item-action py-3 text-uppercase active" id="list-home-list" data-bs-toggle="list" href="#list-home" role="tab" aria-controls="home">TamilNadu<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className='float-end text-uppercase' width="15"><path d="M269.7 250.3c3.1 3.1 3.1 8.2 0 11.3l-176 176c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3L252.7 256 82.3 85.7c-3.1-3.1-3.1-8.2 0-11.3s8.2-3.1 11.3 0l176 176z"/></svg></a>
                        <a className="list-group-item list-group-item-action py-3 text-uppercase" id="list-profile-list" data-bs-toggle="list" href="#list-profile" role="tab" aria-controls="profile">Maharastra<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className='float-end text-uppercase' width="15"><path d="M269.7 250.3c3.1 3.1 3.1 8.2 0 11.3l-176 176c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3L252.7 256 82.3 85.7c-3.1-3.1-3.1-8.2 0-11.3s8.2-3.1 11.3 0l176 176z"/></svg></a>
                        <a className="list-group-item list-group-item-action py-3 text-uppercase" id="list-messages-list" data-bs-toggle="list" href="#list-messages" role="tab" aria-controls="messages">Karnataka<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className='float-end text-uppercase' width="15"><path d="M269.7 250.3c3.1 3.1 3.1 8.2 0 11.3l-176 176c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3L252.7 256 82.3 85.7c-3.1-3.1-3.1-8.2 0-11.3s8.2-3.1 11.3 0l176 176z"/></svg></a>
                        <a className="list-group-item list-group-item-action py-3 text-uppercase" id="list-settings-list" data-bs-toggle="list" href="#list-settings" role="tab" aria-controls="settings">Kerala <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className='float-end text-uppercase' width="15"><path d="M269.7 250.3c3.1 3.1 3.1 8.2 0 11.3l-176 176c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3L252.7 256 82.3 85.7c-3.1-3.1-3.1-8.2 0-11.3s8.2-3.1 11.3 0l176 176z"/></svg></a>
                        <a className="list-group-item list-group-item-action py-3 text-uppercase" id="list-settings2-list" data-bs-toggle="list" href="#list-settings2" role="tab" aria-controls="settings2">West Bengal <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className='float-end text-uppercase' width="15"><path d="M269.7 250.3c3.1 3.1 3.1 8.2 0 11.3l-176 176c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3L252.7 256 82.3 85.7c-3.1-3.1-3.1-8.2 0-11.3s8.2-3.1 11.3 0l176 176z"/></svg></a>
                      </div>
                    </div>
                    <div className="col-12 col-lg-8 col-xl-8">
                      <div className="tab-content shadow p-4 rounded h-100" id="list-nav-tabContent">
                        <div className="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list">
                          <div className='d-block d-lg-flex d-xl-flex gap-5 justify-content-evenly'>
                            <div className='lh-lg'>
                              <h6 className='fw-bold mb-3'>Chennai Office</h6>
                              <p className='mb-0'>Visaka Industries Limited,</p>
                              <p className='mb-0'>Plot No. 1151, 12<sup>th</sup> sector,</p>
                              <p className='mb-0'>75<sup>th</sup> Street,</p>
                              <p className='mb-0'>K.K. NAGAR – West,</p>
                              <p className='mb-0'>Chennai- 600078.</p>
                              <p className='mb-0'>Tel No: 8886786398</p>
                              <p className='mb-0'>chennai.boards@visaka.in</p>
                            </div>
                            <div className='lh-lg'>
                              <h6 className='fw-bold mb-3'>Coimbatore Office</h6>
                              <p className='mb-0'>Visaka Industries Limited,</p>
                              <p className='mb-0'>No.273, Dr.Sri Hari</p>
                              <p className='mb-0'>Building 1<sup>st</sup> Floor,</p>
                              <p className='mb-0'>Police Kandaswamy Street,</p>
                              <p className='mb-0'>Puliakulam Road</p>
                              <p className='mb-0'>Coimbatore - 641045, TamilNadu</p>
                              <p className='mb-0'>Tel No: 8886786398</p>
                              <p className='mb-0'>coimbatore.boards@visaka.in</p>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="list-profile" role="tabpanel" aria-labelledby="list-profile-list">
                          <div className='d-flex gap-5'>
                            <div className='lh-lg mt-2'>
                              <h6 className='fw-bold'>Mumbai Office</h6>
                              <p className='mb-0'>Visaka Industries Limited,</p>
                              <p className='mb-0'>Sanjay Appa Chambers,</p>
                              <p className='mb-0'>501, 5th floor, Plot No. 82,</p>
                              <p className='mb-0'>New Chakala Link Road,</p>
                              <p className='mb-0'>Near J.B.Nagar Metro Station,</p>
                              <p className='mb-0'>Andheri (East),</p>
                              <p className='mb-0'>Mumbai- 400 093,</p>
                              <p className='mb-0'>Phone No. 8886786398.</p>
                            </div>
                            <div className='lh-lg mt-2'>
                              <h6 className='fw-bold'>Pune Office</h6>
                              <p className='mb-0'>A-106, Chourang Smith shilp,</p>
                              <p className='mb-0'>Near A.M. College,</p>
                              <p className='mb-0'>Mahadev Nagar Manjari,</p>
                              <p className='mb-0'>Survey No.93, Taluka: Haveli,</p>
                              <p className='mb-0'>Pune – 412307,</p>
                              <p className='mb-0'>Maharashtra,</p>
                              <p className='mb-0'>Tel: 8886786398</p>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="list-messages" role="tabpanel" aria-labelledby="list-messages-list">
                          <div className='d-flex gap-5'>
                              <div className='lh-lg mt-2'>
                                <h6 className='fw-bold'>Bangalore Office</h6>
                                <p className='mb-0'>Visaka Industries Limited,</p>
                                <p className='mb-0'>Survey No.70, ASF-1,</p>
                                <p className='mb-0'>2nd Floor, Srivari Enclave,</p>
                                <p className='mb-0'>Opp, MEI, Yeshwantpur,</p>
                                <p className='mb-0'>Tumkur Road,</p>
                                <p className='mb-0'>Bangalore – 560 022,</p>
                                <p className='mb-0'>Tel: 8886786398</p>
                                <p className='mb-0'>Email ID : bangalore.boards@visaka.in</p>
                              </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="list-settings" role="tabpanel" aria-labelledby="list-settings-list">
                            <div className='d-flex gap-5'>
                              <div className='lh-lg mt-2'>
                                <h6 className='fw-bold'>Ernakulam Office</h6>
                                <p className='mb-0'>Visaka Industries Limited,</p>
                                <p className='mb-0'>Door No. 50/2141,</p>
                                <p className='mb-0'>Kunnumpuram,</p>
                                <p className='mb-0'>Edappally North,</p>
                                <p className='mb-0'>Ernakulam – 682 024,</p>
                                <p className='mb-0'>Bangalore – 560 022,</p>
                                <p className='mb-0'>KERALA. Ph no: 8886786398</p>
                                <p className='mb-0'>Email ID : marketing.ernakulam@visaka.in</p>
                              </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="list-settings2" role="tabpanel" aria-labelledby="list-settings2-list">
                        <div className='d-flex gap-5'>
                              <div className='lh-lg mt-2'>
                                <h6 className='fw-bold'>Kolkata Office</h6>
                                <p className='mb-0'>Visaka Industries Limited,</p>
                                <p className='mb-0'>Brindaban Garden, Building No A-4,</p>
                                <p className='mb-0'>Room No-2, 98, Christopher Road,</p>
                                <p className='mb-0'>Near Topsia Bypass,</p>
                                <p className='mb-0'>Ernakulam – 682 024,</p>
                                <p className='mb-0'>Kolkatta -700046.</p>
                                <p className='mb-0'>Phone: 8886786398</p>
                                <p className='mb-0'>Email ID : Email ID : kolkata.boards@visaka.in</p>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div className='col-12'>
                <div className='email_us shadow rounded mt-3 p-4'>
                    <h4 className='mb-3'>Email Us</h4>
                    <p className='mb-0'>Export email: export.vboard@visaka.in</p>
                    <p>Domestic email: vnext@visaka.in</p>
                    </div>
              </div>
            </div>
          </div>
          
        </div>
      </DefaultSectionComp>
    <FooterComp/>
    </>
  )
}

export default ContactComp
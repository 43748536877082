import './DefaultSectionComponents.css';

const DefaultSectionComp = ({children, className}) => {
    return (
        <>
            <section className={`section ${className}`}>
                {children}
            </section>
        </>
    );
}
export default DefaultSectionComp;
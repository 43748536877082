import React, { useState, useEffect } from 'react'
import './FormComp.css'
import { useFormik } from 'formik'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PageResetComp from '../page-reset/PageResetComp';
import { API_URL, CAMPAIGN_ID_CONTACT } from '../../../../config/config';

const ContactFormComp = () => {
  
    const [regional, setRegional] = useState([]);
    const [district, setDistrict] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const formik  = useFormik({
        initialValues:{
            name:"",
            email:"",
            phone_number:"",
            state:"",
            district:"",
            city:"",
            category:"",
            campaign_id:"21"
        },
        onSubmit:(values)=>{
            const formData = new FormData(); 
            formData.append("name", values.name);
            formData.append("email", values.email);
            formData.append("phone_number", values.phone_number);
            formData.append("state", values.state);
            formData.append("district", values.district);
            formData.append("city", values.city);
            formData.append("category", values.category);
            formData.append("campaign_id", CAMPAIGN_ID_CONTACT);
            setIsLoading(true);
            axios.post(`${API_URL}/vnext/enquiries/create`, formData)

            .then(response=>
                {
                    formik.resetForm();
                    navigate('/success');
                    setIsLoading(false)
                }
            )
            .catch(err=>console.log(err))
        },
        validate:(values)=>{
            let errors = {};
            if(!values.name){
                errors.name="Full Name is required"
            }else if(values.name.length < 4){
                errors.name="Name Should be Minimum 3 letters"
            }
            if(!values.email){
                errors.email="Email is required"
            }
            else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid Email address';
            }
            if(!values.phone_number){
                errors.phone_number="Mobile Number is required"
            }
            else if(values.phone_number.length!==10){
                errors.phone_number = 'Mobile Number Must Be 10 Digits';
            }
            else if (!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(values.phone_number)) {
                errors.phone_number = 'Please Enter Numbers format only';
            }
            
            if(!values.category){
                errors.category="Category is required"
            }
            if(!values.state){
                errors.state="State is required"
            }
            if(!values.district){
                errors.district="District is required"
            }
            if(!values.city){
                errors.city="City is required"
            }
            return errors;
        }
     });

    const stateURL = "https://d2tm70q7nw2sv.cloudfront.net/assets/locations/json/indianStatesCities.json";
    const fetchStateDistrictInfo = async () => {
        const stateDistrictResponse = await fetch(stateURL);
        const stateDistrictResponseData = await stateDistrictResponse.json();
        setRegional(stateDistrictResponseData);
    }
    const handleStateDistrict = (e) => {
        const selectedState = e.target.value;
        const getDistrict = regional.filter((distitem) => distitem.State === selectedState);
        setDistrict(getDistrict);
        formik.setFieldValue('state', selectedState);
    }
    useEffect(() => {
        fetchStateDistrictInfo();
    }, [])

  return (
    <>  
        {isLoading && <PageResetComp />}
        <div className='shadow p-5 rounded'>
            <form autoComplete='off' onSubmit={formik.handleSubmit}>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control"  value={formik.values.name} onChange={formik.handleChange} name="name" placeholder="Enter Full Name" />
                    <label htmlFor="floatingInput">Enter Full Name</label>
                    {formik.errors.name?<span className='text-danger mt-2 d-block'>{formik.errors.name}</span> : null}
                </div>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control"  value={formik.values.email} onChange={formik.handleChange} name="email" placeholder="Enter email" />
                    <label htmlFor="floatingInput">Enter Email</label>
                    {formik.errors.email? <span className='text-danger mt-2 d-block'>{formik.errors.email}</span> : null}
                </div>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control"  maxLength={10} value={formik.values.phone_number} onChange={formik.handleChange} name="phone_number" placeholder="Enter mobile No."/>
                    <label htmlFor="floatingInput">Enter Mobile No.</label>
                    {formik.errors.phone_number? <span className='text-danger mt-2 d-block'>{formik.errors.phone_number}</span> : null}
                </div>
                <div className="form-floating mb-3">
                    <select className="form-select form-control"  value={formik.values.category} onChange={formik.handleChange} name="category"> 
                        <option value="">Select Category</option>
                        <option value="Architect">Architect</option>
                        <option value="Interior">Interior</option>
                        <option value="Builder">Builder</option>
                        <option value="Contractor">Contractor</option>
                        <option value="Dealership">Dealership</option>
                        <option value="Item enquiry">Item Enquiry</option>
                        <option value="Order enquiry">Order enquiry</option>
                        <option value="Others">Others</option>
                    </select>
                    <label htmlFor="floatingInput">Select Category</label>
                    {formik.errors.category? <span className='text-danger mt-2 d-block'>{formik.errors.category}</span> : null}
                </div>
                <div className='form-floating mb-3'>
                    <select className="form-select" name="state" onChange={handleStateDistrict}  value={formik.values.state}>
                        <option defaultValue>Select State*</option>
                        {regional && Array.from(new Set(regional.map(obj => obj.State))).map((State, index) => {
                            return (<option value={State} key={index}>{State}</option>)
                        })}
                    </select>
                    <label htmlFor="floatingInput">Select State</label>
                    {formik.errors.state? <span className='text-danger mt-2 d-block'>{formik.errors.state}</span> : null}
                </div>
                <div className='form-floating mb-3'>
                    <select className="form-select" name="district"  onChange={formik.handleChange} value={formik.values.district}>
                        <option defaultValue>Select District*</option>
                        {
                            district.map((edist, index) => {
                                return (
                                    <option key={index}>{edist.District}</option>
                                )
                            })
                        }
                    </select>
                    <label htmlFor="floatingInput">Select District</label>
                    {formik.errors.district? <span className='text-danger mt-2 d-block'>{formik.errors.district}</span> : null}
                </div>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" value={formik.values.city} onChange={formik.handleChange} name="city" placeholder="Enter City"/>
                    <label htmlFor="floatingInput">Enter City</label>
                    {formik.errors.city? <span className='text-danger mt-2 d-block'>{formik.errors.city}</span> : null}
                </div>
                <div className="mt-4 d-flex gap-3 justify-content-center">
                    <input type="submit" className="btn w-100 rounded bg-theme border-0 py-3 text-uppercase fw-bold" value="Submit" />
                </div>
            </form>  
        </div> 
    </>
  )
}
export default ContactFormComp
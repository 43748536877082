import React from 'react'
import ReactImageGallery from 'react-image-gallery'


const ApplicationDetailsComp = ({applicationDetails}) => {
  console.log(applicationDetails.gallery);
  return (
    <div className='application-details'>
        <div className='images mb-5'>
            <ReactImageGallery items={applicationDetails.gallery}/>
        </div>
        <div className='title'>
            <h4 className='text-capitalize'>{applicationDetails.title}</h4>
            <p className='lh-lg'>{applicationDetails.desc}</p>
        </div>
    </div>
  )
}
export default ApplicationDetailsComp